jQuery(document).ready(function(){
    $('#check-menu').click(function(){
        $("body, html").toggleClass('hidden');
    });
    $('.simplebox').click(function(){
        $("body, html").toggleClass('modal-hidden');
    });
    $('.close-modal').click(function(){
        $("body, html").removeClass('modal-hidden');
    });
    $('.tabs-box').tabs({
        onChange:function(){
            this.boxes.filter('.visible').not('.has-slick').addClass('has-slick').find('.projects-box').slick({
                lazyLoad: 'ondemand',
                infinite: false,
                slidesToShow: 4,
                responsive: [
                    {
                        breakpoint: 719,
                        settings: {
                            slidesToShow: 2
                        }
                    }
                ]
            });
        }
    });
    $('.reviews-slider').slick({
        lazyLoad: 'ondemand',
        infinite: true,
        slidesToShow: 1,
        adaptiveHeight: true
    });
    startGalleries($('.gallery-col'));
    $('.simplebox').simplebox({
        overlay: {
            color: 'black',
            opacity: 0.8
        },
        linkClose: '.close-modal, .close-success'
    });
    $(".menu > li").click(function() {
        $(".menu > li").removeClass("active-item");
        $(this).toggleClass("active-item");
    });
    $('.menu > li a').click(function(){
        $('#check-menu').prop('checked', false);
        $("body, html").toggleClass('hidden');
    });
    initModal();
    initForm();
});

function initForm() {
    $('.validation').validation({
        errorClass: 'error-form',
    });
    
    $('.ajax-form').each(function(){
        var hold = $(this);

        hold.validation({
            errorClass: 'error-form',
            onValid: function(){
                jQuery.ajax({
                    data: hold.serialize(),
                    dataType: 'html',
                    url: hold.attr('action'),
                    success: function(msg){
                        $.simplebox('#successful-message', {
                            overlay: {
                                color: 'black',
                                opacity: 0.8
                            },
                            linkClose: '.close-modal, .close-success'
                        });
                        console.log('Server success', msg);
                    },
                    error: function(msg){
                        console.log('Server error', msg);
                    }
                });
                return false;
            }
        });
    });
    $(".contact-btn").click(function(){
        $(".ajax-form").trigger("reset");
    });
}

function initModal() {
    $('[data-modal]').each(function (){
        let link = $(this);
        
        link.on('click', function (){
            let modal = generateModal(parseInt(link.data('modal')));

            if (modal != null) {
                $("body, html").addClass('modal-hidden');
                $.simplebox(modal, {
                    overlay: {
                        color: 'black',
                        opacity: 0.8
                    },
                    linkClose: '.close-modal, .close-success',
                    afterOpen: function(obj){
                        startGalleries(modal.find('.gallery-col'));
                    },
                    afterClose: function(obj){
                        $("body, html").removeClass('modal-hidden');
                        modal.find('.gallery-col').trigger('destroy-slick');
                        modal.remove();
                    }
                });
            }
            return false;
        });
    });
    
    function generateModal(i) {
        let slides = '';
        let product = PRODUCTS.find(function (el) { return el.id === i});
        let modal = '';
        
        if (product == null) {
            console.error(`ERROR: Product with ID=${i} does not exist!`);
            return null;
        }
        
        $.each(product.gallery, function (k, el) {
            slides += `<div class="slide"><img src="${el}" alt="image"></div>`;
        });
        
        modal = $(`
            <div class="modal-window" id="${'modal-' + new Date().getTime()}">
                <div class="close-modal"></div>
                <div class="gallery-col">
                    <div class="slider-gallery-projects">${slides}</div>
                    <div class="fade-gallery-projects">${slides}</div>
                </div>
                <div class="about-col">
                    <strong class="medium-title ${product.title == null ? 'hide' : ''}">${product.title}</strong>
                    ${product.html.before || ''}
                    <dl class=" ${product.materials == null ? 'hide' : ''}"><dt>Materials:</dt> <dd>${product.materials}</dd></dl>
                    <dl class=" ${product.size == null ? 'hide' : ''}"><dt>Size:</dt> <dd>${product.size}</dd></dl>
                    <dl class=" ${product.time == null ? 'hide' : ''}"><dt>Time:</dt> <dd>${product.time}</dd></dl>
                    <dl class=" ${product.location == null ? 'hide' : ''}"><dt>Location:</dt> <dd>${product.location}</dd></dl>
                    ${product.html.after || '' }
                    <div class="quote-hold ${product.html.quote == null ? 'hide' : ''}">
                      <blockquote>${product.html.quote || ''}</blockquote>
                      <strong class="user-name">${product.html.quoteName || ''}</strong>
                    </div>
                </div>
            </div>`);
        $('body').append(modal);
        
        return modal;
    }
}

function startGalleries($gallery) {
    $gallery.each(function() {
        let hold = $(this);
        let max = 8;
        let slide = $('.slider-gallery-projects', hold);
        let fade = $('.fade-gallery-projects', hold);
        let id = hold.parent().attr('id');
        
        if(slide.find('.slide').length < max+1) {
            hold.parent().addClass('gallery-fixed');
        }

        fade.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            asNavFor: '#' + id + ' .slider-gallery-projects'
        });
        
        slide.slick({
            slidesToShow: max,
            slidesToScroll: 1,
            verticalSwiping: true,
            arrows: false,
            focusOnSelect: true,
            vertical: true,
            variableHeight: true,
            asNavFor: '#' + id + ' .fade-gallery-projects'
        });
        
        hold.on('destroy-slick', function (){
            slide.slick('unslick');
            fade.slick('unslick');
            hold.off('destroy-slick');
        })
    });
}